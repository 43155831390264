<template>
  <div id="top-header">
    <div class="titlebar">
      <dv-decoration-8 class="header-left-decoration" />
      <dv-decoration-5 class="header-center-decoration" />
      <dv-decoration-8
        class="header-right-decoration"
        :reverse="true"
      />
      <div class="center-title text-line1">
        {{ title }}
      </div>
    </div>
    <div class="infobar">
      <div class="infoleft">
        <div class="date">
          <icon
            name="date"
            :scale="4"
          /><span class="text">{{ date }}</span>
        </div>
        <div class="time">
          <icon
            name="time"
            :scale="4"
          />
          <span class="text">{{ time }}</span>
        </div>
      </div>
      <div class="inforight">
        <icon
          :name="icons[weather]"
          :scale="8"
          style="width: 80px; height: 80px;"
        />
        <div class="tianqi-info">
          <span class="tianqi">{{ weather }}</span>
          <span class="qiwen">{{ weatherTemp }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopHeader',
  props: {
    title: {
      type: String,
      default: '苏州老宝贝共享设备租赁监控平台'
    },
    weather: {
      type: String,
      default: '晴'
    },
    weatherTemp: {
      type: String,
      default: '15°C~23°C'
    }
  },
  data () {
    const vm = this
    return {
      icons: {
        晴: 'tq-qing',
        多云: 'tq-duoyun',
        阴: 'tq-yin',
        小雨: 'tq-xiaoyu'
      },
      date: vm.dateFormat('YYYY年mm月dd日 星期W', new Date()),
      time: vm.dateFormat('HH:MM:SS', new Date())
    }
  },
  mounted () {
    const vm = this
    setInterval(() => {
      vm.date = vm.dateFormat('YYYY年mm月dd日 星期W', new Date())
      vm.time = vm.dateFormat('HH:MM:SS', new Date())
    }, 1000)
  },
  methods: {
    dateFormat (fmt, date) {
      let ret
      const we = date.getDay()
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
      }
      const week = { // 中文数字 (星期)
        0: '日',
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六'
      }
      if (/(W+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '星期' + week[we] : '周' + week[we]) : week[we]))
      };
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
        };
      };
      return fmt
    }
  }
}
</script>

<style lang="scss" scoped>
    #top-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-shrink: 0;
        height: 160px;

        .titlebar {
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: space-between;
            flex-shrink: 0;

            .header-center-decoration {
                width: 40%;
                height: 60px;
                margin-top: 90px;
            }
            .header-left-decoration, .header-right-decoration {
                width: 25%;
                height: 50px;
            }
            .center-title {
                text-align: center;
                width: 50%;
                position: absolute;
                font-size: 48px;
                font-weight: bold;
                left: 50%;
                top: 20px;
                transform: translateX(-50%);
                color: #8DC0FC;
                font-family: SourceHanSansSC-bold;
            }
        }

        .infobar {
            position: fixed;
            top: 60px;
            padding: 0 40px;
            display: flex;
            width: calc(100% - 80px);
            flex-direction: row;
            justify-content: space-between;

            .infoleft {

                .date {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    .text {
                        margin-left: 20px;
                        font-size: 26px;
                    }
                }

                .time {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    .text {
                        margin-left: 20px;
                        font-size: 24px;
                    }
                }
            }

            .inforight {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                .tianqi-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    margin-left: 20px;

                    .tianqi {
                        font-size: 28px;
                    }

                    .qiwen {
                        margin-top: 10px;
                        font-size: 18px;
                    }
                }
            }

        }

    }
</style>
