<template>
  <div class="index">
    <div
      class="warp"
      ref="warp"
    >
      <dv-loading v-if="loading">
        <span style="font-size: 18px; padding: 20px; display: flex;">正在加载数据...请稍后...</span>
      </dv-loading>
      <template v-else>
        <div
          v-if="error"
          style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;"
        >
          <div style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <div style="font-size: 24px;">
              抱歉，数据加载失败了！
            </div>
            <div style="font-size: 18px; margin: 50px 0;">
              {{ errorInfo }}
            </div>
            <el-button
              type="primary"
              round
              style="width: 200px; font-size: 20px;"
              @click.stop="retry"
            >
              再试一次
            </el-button>
          </div>
        </div>
        <template v-else>
          <top-header
            v-if="pageData"
            :title="appname ? appname : pageData.mechanism_name"
            :weather="pageData.weather"
            :weather-temp="pageData.weather_temp"
          />
          <div
            v-if="pageData"
            class="container"
          >
            <dv-border-box-1
              :color="['rgba(70, 206, 232, 100)', 'rgba(42, 98, 148, 100)']"
              background-color="rgba(18, 57, 102, 0.96)"
              style="flex: 6;overflow: hidden;"
            >
              <div
                style="display: flex; flex-direction: column; width: calc(100% - 52px);height: calc(100% - 52px);padding: 26px;"
              >
                <div
                  style="display: flex;flex-direction: row; flex-shrink: 0; margin-bottom: 20px; "
                  v-if="radioBtn1 >= 0"
                >
                  <dv-border-box-8
                    :dur="radioBtn1 == 0 ? 3 : 0"
                    :color="['rgba(0, 159, 239, 71)']"
                    :class="'radio-btn ' + (radioBtn1 == 0 ? 'radio-checked' : '')"
                    style="flex: 1;height: 50px;margin-right: 8px"
                  >
                    <span
                      class="label"
                      @click.stop="radioBtn1Change(0)"
                    >设备状态</span>
                  </dv-border-box-8>
                  <dv-border-box-8
                    :dur="radioBtn1 == 1 ? 3 : 0"
                    :color="['rgba(0, 159, 239, 71)']"
                    :class="'radio-btn ' + (radioBtn1 == 1 ? 'radio-checked' : '')"
                    style="flex: 1;height: 50px;margin-left: 8px"
                  >
                    <span
                      class="label"
                      @click.stop="radioBtn1Change(1)"
                    >租赁点排行</span>
                  </dv-border-box-8>
                </div>

                <swiper
                  @slideChange="slideChange"
                  v-if="radioBtn1 == 0"
                  style="flex: 1; width: 100%;"
                  :auto-update="true"
                  :auto-destroy="true"
                  :delete-instance-on-destroy="true"
                  :cleanup-styles-on-destroy="true"
                  :options="{
                    loop: checkLoop(),
                    speed: pageData.equ_center.speed,
                    direction: 'vertical',
                    slidesPerView: 'auto',
                    observer: true,
                    observeParents: true,
                    autoplay: {
                      delay: pageData.equ_center.delay,
                      disableOnInteraction: false
                    },
                  }"
                >
                  <swiper-slide
                    v-for="(item, index) in pageData.equ_center.data"
                    :key="index"
                    style="height: auto !important;"
                  >
                    <dv-border-box12
                      ref="border"
                      :key="'border' + index"
                      :style="'padding: 16px 24px;width: calc(100% - 48px); overflow: hidden;margin-bottom: 10px; height: ' + (item.data.length * 50 + 80) + 'px;'"
                    >
                      <div style="display: flex;flex-direction: column; height: 100%;">
                        <div class="title1">
                          {{ item.name }}
                        </div>
                        <table style="width: 100%;border-collapse: collapse;">
                          <tr style="height: 50px;">
                            <td style="width: 120px">
                              设备
                            </td>
                            <td style="width: 30%">
                              使用中
                            </td>
                            <td style="width: 30%">
                              空闲
                            </td>
                          </tr>
                        </table>
                        <table style="width: 100%;border-collapse: collapse;">
                          <template
                            v-for="(item2, index1) in item.data"
                          >
                            <tr
                              style="height: 50px;border-top: 1px solid rgba(46, 106, 179, 100);width: 40%;"
                              :key="index1"
                            >
                              <td>
                                <div
                                  style="width: 120px"
                                  class="text-line1"
                                >
                                  {{ item2.name }}
                                </div>
                              </td>
                              <td style="display:table-cell; vertical-align:middle;width: 30%;">
                                <div style="display: flex;align-items: center;flex-direction: row">
                                  <span class="using" />{{ item2.using }}
                                </div>
                              </td>
                              <td style="display:table-cell; vertical-align:middle;width: 30%;">
                                <div style="display: flex;align-items: center;flex-direction: row">
                                  <span class="normal" />{{ item2.normal }}
                                </div>
                              </td>
                            </tr>
                          </template>
                        </table>
                      </div>
                    </dv-border-box12>
                  </swiper-slide>
                </swiper>

                <div
                  v-if="radioBtn1 == 1"
                  style="flex: 1; display: flex;flex-direction: column; overflow: hidden;"
                >
                  <div style="padding-bottom: 16px;display: flex;flex-direction: row; flex-shrink: 0; margin-bottom: 20px; align-items: center; justify-content: space-between;border-bottom: 1px solid rgba(40, 82, 132, 100);">
                    <div style="font-size: 18px;">
                      统计时间：<br>{{ pageData.lease_center.updated }}
                    </div>
                    <div
                      style="display: flex; flex-direction: row; align-items: center"
                      v-if="dateRadioBtn0 >= 0"
                    >
                      <dv-border-box-8
                        :dur="dateRadioBtn0 == 0 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn0 == 0 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn0Change(0)"
                        >日</span>
                      </dv-border-box-8>
                      <dv-border-box-8
                        :dur="dateRadioBtn0 == 1 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn0 == 1 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;margin-left: 16px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn0Change(1)"
                        >周</span>
                      </dv-border-box-8>
                      <dv-border-box-8
                        :dur="dateRadioBtn0 == 2 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn0 == 2 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;margin-left: 16px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn0Change(2)"
                        >月</span>
                      </dv-border-box-8>
                    </div>
                  </div>

                  <swiper
                    @slideChange="slideChange"
                    style="flex: 1; width: 100%;"
                    :auto-update="true"
                    :auto-destroy="true"
                    :delete-instance-on-destroy="true"
                    :cleanup-styles-on-destroy="true"
                    :options="{
                      loop: checkLoop2(),
                      speed: pageData.lease_center.speed,
                      direction: 'vertical',
                      slidesPerView: 'auto',
                      observer: true,
                      observeParents: true,
                      autoplay: {
                        delay: pageData.lease_center.delay,
                        disableOnInteraction: false
                      },
                    }"
                  >
                    <swiper-slide
                      v-for="(item, index) in pageData.lease_center.data"
                      :key="index"
                      style="height: auto !important;"
                    >
                      <dv-border-box-12
                        v-if="item && item[dateKeys[dateRadioBtn0]]"
                        :style="'padding: 16px 24px;width: calc(100% - 48px); margin-bottom: 10px; height: ' + (item[dateKeys[dateRadioBtn0]].length * 43 + 80) + 'px;'"
                      >
                        <div style="display: flex;flex-direction: column; height: 100%;">
                          <div class="title1">
                            {{ item.name }}
                          </div>
                          <el-table
                            :highlight-current-row="false"
                            :data="item[dateKeys[dateRadioBtn0]]"
                            style="width: 100%;"
                            :default-sort="{prop: 'equ_count', order: 'descending'}"
                          >
                            <el-table-column
                              prop="name"
                              label="设备"
                              sortable
                            >
                              <template slot-scope="scope">
                                <div class="text-line1">
                                  {{ scope.row.name }}
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="equ_count"
                              label="数量"
                              sortable
                              width="70"
                              align="center"
                            />
                            <el-table-column
                              prop="lease_user"
                              label="租赁人数"
                              sortable
                              align="center"
                            />
                            <el-table-column
                              prop="lease_count"
                              label="租赁次数"
                              sortable
                              align="center"
                            />
                          </el-table>
                        </div>
                      </dv-border-box-12>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </dv-border-box-1>

            <div
              v-if="pageData.equ_lease || pageData.real_lease"
              style="flex: 14;height: 100%;display: flex; flex-direction: column; overflow: hidden;"
            >
              <dv-border-box-1
                v-if="pageData.real_lease"
                style="flex: 10;overflow: hidden;"
                :color="['rgba(70, 206, 232, 100)', 'rgba(42, 98, 148, 100)']"
                background-color="rgba(18, 57, 102, 0.96)"
              >
                <div style="display: flex; flex-direction: column; width: calc(100% - 52px);height: calc(100% - 52px);padding: 26px;">
                  <div style="padding-bottom: 16px;display: flex;flex-direction: row; flex-shrink: 0; align-items: center; justify-content: space-between;border-bottom: 1px solid rgba(40, 82, 132, 100);">
                    <div style="width: 160px; ">
                      <dv-border-box-8
                        :dur="3"
                        :color="['rgba(0, 159, 239, 71)']"
                        class="radio-btn radio-checked"
                        style="height: 50px;"
                      >
                        <span
                          class="label"
                        >实时租借数据</span>
                      </dv-border-box-8>
                    </div>
                    <div @click.stop="switchFullscreen">
                      <icon
                        :name="isFullscreen ? 'exit-full' : 'enter-full'"
                        scale="3"
                      />
                    </div>
                  </div>
                  <div style="display: flex;flex-direction: row; flex-shrink: 0; align-items: center; justify-content: space-between; margin-top: 20px;">
                    <div style="margin-left: 20px;">
                      <div style="font-size: 28px;">
                        运营数据统计
                      </div>
                      <div style="font-size: 18px; margin-top: 5px;">
                        数据更新时间：{{ pageData.real_lease.updated }}
                      </div>
                    </div>
                    <div
                      style="display: flex; flex-direction: row; align-items: center; margin-right: 20px;"
                      v-if="dateRadioBtn1 >= 0"
                    >
                      <dv-border-box-8

                        v-if="pageData.real_lease.day"
                        :dur="dateRadioBtn1 == 0 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn1 == 0 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn1Change(0)"
                        >日</span>
                      </dv-border-box-8>
                      <dv-border-box-8
                        v-if="pageData.real_lease.week"
                        :dur="dateRadioBtn1 == 1 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn1 == 1 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;margin-left: 16px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn1Change(1)"
                        >周</span>
                      </dv-border-box-8>
                      <dv-border-box-8
                        v-if="pageData.real_lease.month"
                        :dur="dateRadioBtn1 == 2 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn1 == 2 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;margin-left: 16px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn1Change(2)"
                        >月</span>
                      </dv-border-box-8>
                    </div>
                  </div>
                  <div
                    v-if="pageData.real_lease[dateKeys[dateRadioBtn1]] !== undefined"
                    style="display: flex; width: 100%; flex: 1; flex-direction: row; margin-top: 36px; margin-bottom: 20px;"
                  >
                    <div style="display: flex; height: 100%; flex: 1; background-color: #32C5D2; margin:0 10px;border-radius: 10px; flex-direction: column;justify-content: space-between; overflow: hidden;">
                      <div style="margin-left: 20px; font-size: 20px; padding: 8px 0;">
                        租赁人数
                      </div>
                      <div style="flex: 1; text-align: left; font-size: 36px; font-weight: bold; margin-left: 20px;display: flex; align-items: center;">
                        <animated-number
                          :value="
                            pageData.real_lease[dateKeys[dateRadioBtn1]].user_total
                          "
                          :duration="1000"
                          :format-value="formatIntegerNumber"
                        />人
                      </div>
                      <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; background: rgba(0,0,0,0.1); height: 40px; margin-top: 8px;">
                        <template v-if="pageData.real_lease[dateKeys[dateRadioBtn1]].is_user_up == 1">
                          <div style="font-size: 18px;margin-left: 20px;">
                            日环比上涨<animated-number
                              :value="
                                pageData.real_lease[dateKeys[dateRadioBtn1]].user_change
                              "
                              :duration="1000"
                              :format-value="formatIntegerNumber"
                            />人
                          </div>
                          <icon
                            name="sheng"
                            scale="3"
                            style="color: #FF9E00;margin-right: 20px;"
                          />
                        </template>
                        <template v-else>
                          <div style="font-size: 18px;margin-left: 20px;">
                            日环比下降<animated-number
                              :value="
                                pageData.real_lease[dateKeys[dateRadioBtn1]].user_change
                              "
                              :duration="1000"
                              :format-value="formatIntegerNumber"
                            />人
                          </div>
                          <icon
                            name="jiang"
                            scale="3"
                            style="color: #FF9E00;margin-right: 20px;"
                          />
                        </template>
                      </div>
                    </div>

                    <div style="display: flex; height: 100%; flex: 1; background-color: #8E44AD; margin:0 10px;border-radius: 10px; flex-direction: column;justify-content: space-between; overflow: hidden;">
                      <div style="margin-left: 20px; font-size: 20px; padding: 8px 0;">
                        租赁频次
                      </div>
                      <div style="flex: 1; text-align: left; font-size: 36px; font-weight: bold; margin-left: 20px;display: flex; align-items: center;">
                        <animated-number
                          :value="
                            pageData.real_lease[dateKeys[dateRadioBtn1]].lease_total
                          "
                          :duration="1000"
                          :format-value="formatIntegerNumber"
                        />次
                      </div>
                      <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; background: rgba(0,0,0,0.1); height: 40px; margin-top: 8px;">
                        <template v-if="pageData.real_lease[dateKeys[dateRadioBtn1]].is_lease_up == 1">
                          <div style="font-size: 18px;margin-left: 20px;">
                            日环比上涨<animated-number
                              :value="
                                pageData.real_lease[dateKeys[dateRadioBtn1]].lease_change
                              "
                              :duration="1000"
                              :format-value="formatIntegerNumber"
                            />次
                          </div>
                          <icon
                            name="sheng"
                            scale="3"
                            style="color: #FF9E00;margin-right: 20px;"
                          />
                        </template>
                        <template v-else>
                          <div style="font-size: 18px;margin-left: 20px;">
                            日环比下降<animated-number
                              :value="
                                pageData.real_lease[dateKeys[dateRadioBtn1]].lease_change
                              "
                              :duration="1000"
                              :format-value="formatIntegerNumber"
                            />次
                          </div>
                          <icon
                            name="jiang"
                            scale="3"
                            style="color: #FF9E00;margin-right: 20px;"
                          />
                        </template>
                      </div>
                    </div>

                    <div style="display: flex; height: 100%; flex: 1; background-color: #3598DC; margin:0 10px;border-radius: 10px; flex-direction: column;justify-content: space-between; overflow: hidden;">
                      <div style="margin-left: 20px; font-size: 20px; padding: 8px 0;">
                        设备数量
                      </div>
                      <div style="flex: 1;display: flex; flex-direction: row; flex-wrap: wrap;padding: 0 20px; justify-content: flex-start; margin-top: 5px; overflow: hidden;">
                        <div
                          v-for="(item, index) in pageData.real_lease[dateKeys[dateRadioBtn1]].equ_lease"
                          style="width: calc(50%);font-size: 16px; overflow: hidden;"
                          :key="index"
                        >
                          <div
                            style="max-width: 50%; display: inline-flex;"
                          >
                            <span class="text-line1">{{ item.name }}</span>
                          </div><div style="width: 40%; display: inline-flex; align-items: center;">
                            ：<animated-number
                              :value="
                                item.count
                              "
                              :duration="1000"
                              :format-value="formatIntegerNumber"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dv-border-box-1>

              <dv-border-box-1
                v-if="pageData.equ_lease"
                style="flex: 12;overflow: hidden;"
                :color="['rgba(70, 206, 232, 100)', 'rgba(42, 98, 148, 100)']"
                background-color="rgba(18, 57, 102, 0.96)"
              >
                <div style="display: flex; flex-direction: column; width: calc(100% - 52px);height: calc(100% - 52px);padding: 26px;">
                  <div style="padding-bottom: 16px;display: flex;flex-direction: row; flex-shrink: 0; align-items: center; justify-content: space-between;border-bottom: 1px solid rgba(40, 82, 132, 100);">
                    <div
                      style="display: flex; flex-direction: row;"
                      v-if="radioBtn2 >= 0"
                    >
                      <div style="width: 186px; margin-right: 20px; display: flex;">
                        <dv-border-box-8
                          :dur="radioBtn2 == 0 ? 3 : 0"
                          :color="['rgba(0, 159, 239, 71)']"
                          :class="'radio-btn ' + (radioBtn2 == 0 ? 'radio-checked' : '')"
                          style="height: 50px;"
                        >
                          <span
                            class="label"
                            @click.stop="radioBtn2Change(0)"
                          >设备租借次数对比</span>
                        </dv-border-box-8>
                      </div>
                      <div style="width: 186px;">
                        <dv-border-box-8
                          :dur="radioBtn2 == 1 ? 3 : 0"
                          :color="['rgba(0, 159, 239, 71)']"
                          :class="'radio-btn ' + (radioBtn2 == 1 ? 'radio-checked' : '')"
                          style="height: 50px;"
                        >
                          <span
                            class="label"
                            @click.stop="radioBtn2Change(1)"
                          >设备租借次数占比</span>
                        </dv-border-box-8>
                      </div>
                    </div>
                    <div
                      style="display: flex; flex-direction: row; align-items: center; margin-right: 20px;"
                      v-if="dateRadioBtn2 >= 0"
                    >
                      <dv-border-box-8
                        v-if="pageData.equ_lease.day"
                        :dur="dateRadioBtn2 == 0 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn2 == 0 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn2Change(0)"
                        >日</span>
                      </dv-border-box-8>
                      <dv-border-box-8
                        v-if="pageData.equ_lease.week"
                        :dur="dateRadioBtn2 == 1 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn2 == 1 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;margin-left: 16px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn2Change(1)"
                        >周</span>
                      </dv-border-box-8>
                      <dv-border-box-8
                        v-if="pageData.equ_lease.month"
                        :dur="dateRadioBtn2 == 2 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn2 == 2 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;margin-left: 16px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn2Change(2)"
                        >月</span>
                      </dv-border-box-8>
                    </div>
                  </div>
                  <div
                    style="display: flex; width: 100%; flex: 1; flex-direction: row; margin-top: 20px;"
                  >
                    <ECharts
                      v-if="radioBtn2 == 0"
                      theme="dark"
                      autoresize
                      :options="getBarChartOptions(pageData.equ_lease[dateKeys[dateRadioBtn2]])"
                      style="width: 100%; height: 100%;"
                    />
                    <ECharts
                      v-if="radioBtn2 == 1"
                      theme="dark"
                      autoresize
                      :options="getPieChartOptions(pageData.equ_lease[dateKeys[dateRadioBtn2]])"
                      style="width: 100%; height: 100%;"
                    />
                  </div>
                </div>
              </dv-border-box-1>
            </div>

            <div
              style="flex: 6;height: 100%;display: flex; flex-direction: column; overflow: hidden;"
              v-if="pageData.top_lease || (pageData.inspect_record && pageData.inspect_record.data && pageData.inspect_record.data.length > 0)"
            >
              <dv-border-box-1
                v-if="pageData.top_lease"
                style="flex: 14;overflow: hidden;"
                :color="['rgba(70, 206, 232, 100)', 'rgba(42, 98, 148, 100)']"
                background-color="rgba(18, 57, 102, 0.96)"
              >
                <div style="display: flex; flex-direction: column; width: calc(100% - 52px);height: calc(100% - 52px);padding: 26px;">
                  <div style="padding-bottom: 16px;display: flex;flex-direction: row; flex-shrink: 0; margin-bottom: 20px; align-items: center; justify-content: space-between;border-bottom: 1px solid rgba(40, 82, 132, 100);">
                    <div style="font-size: 28px;">
                      设备租赁排行
                    </div>
                    <div
                      style="display: flex; flex-direction: row; align-items: center"
                      v-if="dateRadioBtn3 >= 0"
                    >
                      <dv-border-box-8
                        :dur="dateRadioBtn3 == 0 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn3 == 0 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn3Change(0)"
                        >日</span>
                      </dv-border-box-8>
                      <dv-border-box-8
                        :dur="dateRadioBtn3 == 1 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn3 == 1 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;margin-left: 16px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn3Change(1)"
                        >周</span>
                      </dv-border-box-8>
                      <dv-border-box-8
                        :dur="dateRadioBtn3 == 2 ? 3 : 0"
                        :color="['rgba(0, 159, 239, 71)']"
                        :class="'radio-btn ' + (dateRadioBtn3 == 2 ? 'radio-checked' : '')"
                        style="width: 30px;height: 50px;margin-left: 16px;"
                      >
                        <span
                          class="label"
                          @click.stop="dateRadioBtn3Change(2)"
                        >月</span>
                      </dv-border-box-8>
                    </div>
                  </div>
                  <div style="flex: 1; display: flex;flex-direction: column; overflow: hidden;">
                    <div style="width: calc(100% - 40px);display: flex; padding: 0 20px; justify-content: space-between;">
                      <div>
                        设备
                      </div>
                      <div>
                        租借次数
                      </div>
                    </div>
                    <div
                      style="overflow-y: auto; width: 100%; flex: 1;"
                      v-if="pageData.top_lease[dateKeys[dateRadioBtn3]] !== undefined"
                    >
                      <ul style="width: 100%; list-style-type: none; padding: 0;">
                        <template
                          v-for="(item, index) in pageData.top_lease[dateKeys[dateRadioBtn3]]"
                        >
                          <li
                            :key="index"
                            :style="'padding: 0 15px; width: calc(100% - 30px); display: flex; align-items: center; justify-content: space-between; flex-direction: row; height: 50px;' + (index % 2 == 0 ? 'background-color: rgba(251, 251, 251, 0.1);' : '')"
                          >
                            <div :style="'width: 20px; height: 20px; text-align: center;line-height: 20px; color: #000000; border-radius: 50%; background-color: ' + (index < 5 ? colors1[index] : '#FFFFFF')">
                              {{ index + 1 }}
                            </div>
                            <div
                              style="width: 100px;text-align: left; margin-left: 10px;"
                              class="text-line1"
                            >
                              {{ item.name }}
                            </div>
                            <el-progress
                              :percentage="item.count / pageData.top_lease[dateKeys[dateRadioBtn3]][0].count * 100"
                              style="flex: 1;"
                              :show-text="false"
                              :stroke-width="22"
                              color="#FFBA00"
                              class="progress1"
                            />
                            <div :style="'font-weight: 600;width: 80px;text-align: right;color: ' + (index < 5 ? colors1[index] : '#FFFFFF')">
                              <animated-number
                                :value="
                                  item.count
                                "
                                :duration="1000"
                                :format-value="formatIntegerNumber"
                              />
                            </div>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </div>
                </div>
              </dv-border-box-1>
              <dv-border-box-1
                v-if="pageData.inspect_record && pageData.inspect_record.data && pageData.inspect_record.data.length > 0"
                style="flex: 10;overflow: hidden;"
                :color="['rgba(70, 206, 232, 100)', 'rgba(42, 98, 148, 100)']"
                background-color="rgba(18, 57, 102, 0.96)"
              >
                <div style="display: flex; flex-direction: column; width: calc(100% - 52px);height: calc(100% - 52px);padding: 26px;">
                  <div style="padding-bottom: 16px;display: flex;flex-direction: row; flex-shrink: 0; align-items: center; justify-content: space-between;border-bottom: 1px solid rgba(40, 82, 132, 100);">
                    <div style="font-size: 28px;">
                      巡检记录
                    </div>
                  </div>

                  <swiper
                    @slideChange="slideChange"
                    style="flex: 1; width: 100%;"
                    :auto-update="true"
                    :auto-destroy="true"
                    :delete-instance-on-destroy="true"
                    :cleanup-styles-on-destroy="true"
                    :options="{
                      loop: checkLoop3(),
                      speed: pageData.inspect_record.speed,
                      direction: 'vertical',
                      slidesPerView: 'auto',
                      observer: true,
                      observeParents: true,
                      autoplay: {
                        delay: pageData.inspect_record.delay,
                        disableOnInteraction: false
                      },
                    }"
                  >
                    <swiper-slide
                      v-for="(item, index) in pageData.inspect_record.data"
                      :key="index"
                      style="height: auto !important;"
                    >
                      <li
                        :key="index"
                        :style="'padding: 0 15px; width: calc(100% - 30px); display: flex; align-items: center; justify-content: space-between; flex-direction: row; height: 46px;' + (index % 2 == 0 ? 'background-color: rgba(251, 251, 251, 0.1);' : '')"
                      >
                        <div style="flex: 2;text-align: left; ">
                          {{ item.date }}
                        </div>
                        <div style="flex: 1;text-align: left;">
                          {{ item.username }}
                        </div>
                        <div :style="'flex: 2;text-align: right; color: ' + (item.isFinish == 1 ? '#04d800' : '#ffffff')">
                          {{ item.isFinish == 1 ? '巡检完成' : '正在巡检' }}
                        </div>
                      </li>
                    </swiper-slide>
                  </swiper>
                </div>
              </dv-border-box-1>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'
import { toggleFull } from 'be-full'
import TopHeader from '../../components/TopHeader/index'
import $ from 'jquery'
import 'echarts'
import ECharts from '@/plugins/echarts'
import { getPageData } from '@/api/index'
import SwiperCore, { Autoplay } from 'swiper/core'
SwiperCore.use([Autoplay])

export default {
  name: 'Theme1',
  components: { ECharts, TopHeader, AnimatedNumber },
  data () {
    return {
      appname: null,
      equCenterIndex: 0,
      loading: true,
      error: false,
      errorInfo: '',
      pageData: null,
      radioBtn1: 0,
      radioBtn2: 0,
      dateRadioBtn0: 0,
      dateRadioBtn1: 0,
      dateRadioBtn2: 0,
      dateRadioBtn3: 0,
      isFullscreen: false,
      dateKeys: [
        'day',
        'week',
        'month'
      ],
      colors1: [
        '#FF3A30',
        '#FF9502',
        '#00BB7A',
        '#9AD1F0',
        '#FFFFFF'
      ],
      apiUrl: 'theme1',
      ir: null
    }
  },
  mounted () {
    if (this.$route.query.appname && this.$route.query.appname !== undefined && this.$route.query.appname.length > 0) {
      this.appname = decodeURI(this.$route.query.appname)
    } else {
      this.appname = null
    }

    if (this.$route.query.api && this.$route.query.api !== undefined && this.$route.query.api.length > 0) {
      this.apiUrl = decodeURI(this.$route.query.api)
    }

    if (this.$route.query.ir && this.$route.query.ir !== undefined && this.$route.query.ir.length > 0) {
      this.ir = decodeURI(this.$route.query.ir)
    }
    if (this.apiUrl.indexOf('http://') === -1 && this.apiUrl.indexOf('https://') === -1) {
      this.apiUrl = '/static/json/' + this.apiUrl + '.json'
    }
    if (this.ir) {
      if (this.apiUrl.indexOf('?') === -1) {
        this.apiUrl = this.apiUrl + '?ir=' + this.ir
      } else {
        this.apiUrl = this.apiUrl + '&ir=' + this.ir
      }
    }
    console.log('接口地址：' + this.apiUrl)

    document.addEventListener('keyup', this.pressEnter)
    this.screenSize(this.$refs.warp)
    setTimeout(() => {
      this.refreshData()
    }, 300)
  },
  destroyed () {
    document.removeEventListener('keyup', this.pressEnter)
  },
  methods: {
    checkLoop () {
      const vm = this
      let length = 0
      for (const item of vm.pageData.equ_center.data) {
        length += item.data.length
      }
      return vm.pageData.equ_center.data.length > 2 && length > 10
    },
    checkLoop2 () {
      const vm = this
      if (vm.dateRadioBtn0 < 0) {
        return false
      }
      let length = 0
      for (const item of vm.pageData.lease_center.data) {
        length += item[vm.dateKeys[vm.dateRadioBtn0]].length
      }
      return vm.pageData.lease_center.data.length > 2 && length > 10
    },
    checkLoop3 () {
      const vm = this
      if (vm.pageData.top_lease) {
        return vm.pageData.inspect_record.data.length > 5
      } else {
        return vm.pageData.inspect_record.data.length > 18
      }
    },
    slideChange () {
      const vm = this
      vm.$forceUpdate()
    },
    getBarChartOptions (chartData) {
      if (!chartData || chartData === undefined) {
        return {}
      }
      const legend = []
      const xAxis = []
      const series = []
      chartData.timeline.forEach((item1) => {
        if ($.inArray(item1.time, xAxis) === -1) {
          xAxis.push(item1.time)
        }
        item1.data.forEach((item2) => {
          if ($.inArray(item2.name, legend) === -1) {
            legend.push(item2.name)
          }
        })
      })

      legend.forEach((item1) => {
        const values = []
        chartData.timeline.forEach((item2) => {
          item2.data.forEach((item3) => {
            if (item3.name === item1) {
              values.push(item3.count)
            }
          })
        })
        series.push({
          name: item1,
          data: values,
          type: 'bar',
          barGap: 0
        })
      })

      return {
        textStyle: {
          fontSize: 20
        },
        legend: {
          data: legend
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        color: ['#f3b900', '#faf98e', '#0088f6', '#00b2f6', '#00d6ff'],
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xAxis,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value'
        },
        series: series
      }
    },
    getPieChartOptions (chartData) {
      if (!chartData || chartData === undefined) {
        return {}
      }
      const legend = []
      const data = []
      chartData.summary.data.forEach((item) => {
        if ($.inArray(item.name, legend) === -1) {
          legend.push(item.name)
          data.push({
            name: item.name,
            value: item.count
          })
        }
      })

      return {
        textStyle: {
          fontSize: 20
        },
        legend: {
          data: legend,
          orient: 'vertical',
          right: 20,
          bottom: 20
        },
        title: {
          text: '统计时间：' + chartData.summary.updated,
          right: 20,
          top: 20,
          textStyle: {
            color: '#ffffff'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}次 占{d}%'
        },
        color: ['#f3b900', '#faf98e', '#0088f6', '#00b2f6', '#00d6ff'],
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          containLabel: true
        },
        series: [
          {
            name: '租借次数',
            type: 'pie',
            radius: [60, 150],
            center: ['38%', '50%'],
            data: data.sort(function (a, b) { return a.value - b.value }),
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: '{b} {d}%'
                },
                labelLine: { show: true }
              }
            },
            roseType: 'area',
            label: {
              color: '#ffffff'
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.3)'
              },
              smooth: 0.2,
              length: 10,
              length2: 20
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200
            }
          }
        ]
      }
    },
    formatNumber (num) {
      return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    formatIntegerNumber (num) {
      return this.formatNumber(num.toFixed(0))
    },
    formatFloatNumber (num) {
      return this.formatNumber(num.toFixed(2))
    },
    retry () {
      this.loading = true
      this.error = false
      setTimeout(() => {
        this.refreshData()
      }, 300)
    },
    refreshData () {
      const vm = this
      getPageData(vm.apiUrl).then(res => {
        vm.loading = false

        const pageData = res

        if (pageData.real_lease) {
          if (!pageData.real_lease.day || !pageData.real_lease.day.equ_lease || pageData.real_lease.day.equ_lease.length <= 0) {
            pageData.real_lease.day = null
          }
          if (!pageData.real_lease.week || !pageData.real_lease.week.equ_lease || pageData.real_lease.week.equ_lease.length <= 0) {
            pageData.real_lease.week = null
          }
          if (!pageData.real_lease.month || !pageData.real_lease.month.equ_lease || pageData.real_lease.month.equ_lease.length <= 0) {
            pageData.real_lease.month = null
          }
        }

        if (!pageData.real_lease.day && !pageData.real_lease.week && !pageData.real_lease.month) {
          pageData.real_lease = null
        } else {
          if (pageData.real_lease.day) {
            vm.dateRadioBtn1 = 0
          } else if (pageData.real_lease.week) {
            vm.dateRadioBtn1 = 1
          } else if (pageData.real_lease.month) {
            vm.dateRadioBtn1 = 2
          }
        }

        if (pageData.equ_lease) {
          if (!pageData.equ_lease.day || !pageData.equ_lease.day.timeline || pageData.equ_lease.day.timeline.length <= 0) {
            pageData.equ_lease.day = null
          }
          if (!pageData.equ_lease.week || !pageData.equ_lease.week.timeline || pageData.equ_lease.week.timeline.length <= 0) {
            pageData.equ_lease.week = null
          }
          if (!pageData.equ_lease.month || !pageData.equ_lease.month.timeline || pageData.equ_lease.month.timeline.length <= 0) {
            pageData.equ_lease.month = null
          }

          if (!pageData.equ_lease.day && !pageData.equ_lease.week && !pageData.equ_lease.month) {
            pageData.equ_lease = null
          } else {
            if (pageData.equ_lease.day) {
              vm.dateRadioBtn2 = 0
            } else if (pageData.equ_lease.week) {
              vm.dateRadioBtn2 = 1
            } else if (pageData.equ_lease.month) {
              vm.dateRadioBtn2 = 2
            }
          }
        }

        if (pageData.top_lease) {
          if (!pageData.top_lease.day || pageData.top_lease.day.length <= 0) {
            pageData.top_lease.day = null
          }
          if (!pageData.top_lease.week || pageData.top_lease.week.length <= 0) {
            pageData.top_lease.week = null
          }
          if (!pageData.top_lease.month || pageData.top_lease.month.length <= 0) {
            pageData.top_lease.month = null
          }
        }

        if (!pageData.top_lease.day && !pageData.top_lease.week && !pageData.top_lease.month) {
          pageData.top_lease = null
        } else {
          if (pageData.top_lease.day) {
            vm.dateRadioBtn3 = 0
          } else if (pageData.top_lease.week) {
            vm.dateRadioBtn3 = 1
          } else if (pageData.top_lease.month) {
            vm.dateRadioBtn3 = 2
          }
        }

        vm.pageData = pageData

        console.log('刷新间隔：', vm.pageData.refresh_time)

        setTimeout(() => {
          vm.refreshData()
        }, vm.pageData.refresh_time)
      }).catch(error => {
        console.error(error)
        if (vm.pageData == null) {
          vm.loading = false
          vm.error = true
          vm.errorInfo = error
          setTimeout(() => {
            vm.retry()
          }, 5000)
        } else {
          vm.loading = false
          vm.error = false
          vm.errorInfo = null
          setTimeout(() => {
            vm.refreshData()
          }, 5000)
        }
      })
    },
    radioBtn1Change (index) {
      const vm = this
      if (vm.radioBtn1 !== index) {
        vm.radioBtn1 = -1
        vm.$nextTick(() => {
          vm.radioBtn1 = index
        })
      }
    },
    radioBtn2Change (index) {
      const vm = this
      if (vm.radioBtn2 !== index) {
        vm.radioBtn2 = -1
        vm.$nextTick(() => {
          vm.radioBtn2 = index
        })
      }
    },
    dateRadioBtn0Change (index) {
      const vm = this
      if (vm.dateRadioBtn0 !== index) {
        vm.dateRadioBtn0 = -1
        vm.$nextTick(() => {
          vm.dateRadioBtn0 = index
        })
      }
    },
    dateRadioBtn1Change (index) {
      const vm = this
      if (vm.dateRadioBtn1 !== index) {
        vm.dateRadioBtn1 = -1
        vm.$nextTick(() => {
          vm.dateRadioBtn1 = index
        })
      }
    },
    dateRadioBtn2Change (index) {
      const vm = this
      if (vm.dateRadioBtn2 !== index) {
        vm.dateRadioBtn2 = -1
        vm.$nextTick(() => {
          vm.dateRadioBtn2 = index
        })
      }
    },
    dateRadioBtn3Change (index) {
      const vm = this
      if (vm.dateRadioBtn3 !== index) {
        vm.dateRadioBtn3 = -1
        vm.$nextTick(() => {
          vm.dateRadioBtn3 = index
        })
      }
    },
    switchFullscreen () {
      toggleFull()
      this.isFullscreen = !this.isFullscreen
    },
    pressEnter (event) {
      const code = event.keyCode
        ? event.keyCode
        : (event.which
          ? event.which
          : event.charCode)
      if (code === 13) {
        toggleFull()
      }
    },
    screenSize (editorDom) {
      const screenWidth = document.body.clientWidth || document.documentElement.clientWidth
      const screenHeight = document.body.clientHeight || document.documentElement.clientHeight
      const defWidth = 1920
      const defHeight = 1080
      let xScale = screenWidth / defWidth
      let yScale = screenHeight / defHeight
      editorDom.style.cssText += ';transform: scale(' + xScale + ',' + yScale + ')'

      $(window).resize(() => {
        const screenWidth = document.body.clientWidth || document.documentElement.clientWidth
        const screenHeight = document.body.clientHeight || document.documentElement.clientHeight
        xScale = screenWidth / defWidth
        yScale = screenHeight / defHeight
        editorDom.style.cssText += ';transform: scale(' + xScale + ',' + yScale + ')'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .index {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .warp {
    transform-origin: 0px 0px 0px;
    min-width: auto;
    width: 1920px;
    min-height: auto;
    height: 1080px;
    overflow: auto;
    background-color: #09203D;
    display: flex;
    flex-direction: column;

    .container {
      padding: 0 20px;
      padding-bottom: 20px;
      flex: 1;
      display: flex;
      flex-direction: row;
      overflow: hidden;

      .swiper-container {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
      }

      .title1 {
        font-size: 20px;
        text-align: left;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .using {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 5px;
        background-color: rgba(255, 245, 167, 100);
        margin-right: 10px;
      }

      .normal {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 5px;
        background-color: rgba(139, 195, 74, 100);
        margin-right: 10px;
      }

      .progress1
      {
        /deep/ .el-progress-bar__outer {
          background-color: transparent;
          border-radius: 0;
        }

        /deep/ .el-progress-bar__inner {
          border-radius: 0;
        }
      }

      /deep/ .el-table th > .cell {
          padding-left: 0;
          padding-right: 0;
      }

      /deep/ .el-table {
        background-color: transparent;
        color: #ffffff;
      }

      /deep/ .el-table th {
        background-color: transparent;
      }

      /deep/ .el-table tr {
        background-color: transparent;

      }

        /deep/ .el-table td {
            padding: 8px 0;
            border-bottom: none;border-top: 1px solid rgba(46, 106, 179, 100);
        }

        /deep/ .el-table th.is-leaf  {
            border-bottom: none;
        }

        /deep/ .el-table thead {
            color: #ffffff;
        }

        /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
            background-color: transparent;
        }

        /deep/ .el-table .sort-caret.ascending {
            border-bottom-color: #ffffff;
        }

        /deep/.el-table .sort-caret.descending {
            border-top-color: #ffffff;
        }

        /deep/ .el-table .descending .sort-caret.descending {
            border-top-color: #FFBA00;
        }

        /deep/ .el-table .ascending .sort-caret.ascending {
            border-bottom-color: #FFBA00;
        }

        /deep/ .el-table--group::after, .el-table--border::after, .el-table::before {
            background-color: transparent;
        }
    }

  }
</style>
